import * as React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";
import { cn } from "@/shared/utils";

export interface RadioButtonGroupProps {
    initialValue?: string;
    onValueChange?: (value: string) => void;
    options: { value: string; label: string | React.ReactNode; icon?: React.ReactNode }[];
    disabled?: boolean;
    error?: string;
    styles?: {
        item?: string;
        label?: string;
    };
    className?: string;
    variant?: "default" | "icon";
}

const radioGroupRoot = cva("flex w-full flex-row rounded-md text-xs text-content-primary disabled:cursor-wait", {
    variants: {
        variant: {
            default: "h-11",
            icon: "gap-6 px-1"
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

const radioGroupItem = cva("flex cursor-pointer items-center justify-center whitespace-nowrap text-center focus:outline-none disabled:cursor-wait", {
    variants: {
        variant: {
            default: "flex min-w-0 flex-1 flex-col px-1",
            icon: "flex flex-col items-center"
        },
        state: {
            default: "border-2 border-stroke-transparent bg-background-secondary hover:bg-background-tertiary",
            selected: "border-2 border-stroke-brand-b bg-background-primary",
            iconDefault: "bg-background-primary text-content-inactive",
            iconSelected: "bg-background-primary text-content-primary"
        }
    },
    defaultVariants: {
        variant: "default",
        state: "default"
    }
});

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    initialValue = "",
    onValueChange,
    options,
    className,
    error,
    disabled = false,
    variant = "default",
    styles
}) => {
    const [value, setValue] = React.useState(initialValue);

    const handleChange = (newValue: string) => {
        if (newValue) {
            setValue(newValue);
            if (onValueChange) {
                onValueChange(newValue);
            }
        }
    };

    return (
        <div className={cn(className)}>
            <ToggleGroup.Root type="single" value={value} onValueChange={handleChange} disabled={disabled} className={cn(radioGroupRoot({ variant }))}>
                {options.map((option, index) => {
                    const isSelected = value === option.value;
                    const variantState = variant === "icon" ? (isSelected ? "iconSelected" : "iconDefault") : isSelected ? "selected" : "default";
                    const radioGroupItemClassConditions = {
                        "rounded-l-md": variant === "default" && index === 0,
                        "rounded-r-md": variant === "default" && index === options.length - 1,
                        "focus:rounded-none": variant === "default" && index !== 0 && index !== options.length - 1,
                        "shadow-faux-border-danger": !!error
                    };

                    return (
                        <ToggleGroup.Item
                            key={option.value}
                            value={option.value}
                            disabled={disabled}
                            className={cn(radioGroupItem({ state: variantState, variant }), radioGroupItemClassConditions, styles?.item)}
                        >
                            {option.icon}
                            <label key={option.value} htmlFor={option.value} className={cn("mt-1 w-full cursor-pointer truncate text-sm leading-tight", styles?.label)}>
                                {option.label}
                            </label>
                        </ToggleGroup.Item>
                    );
                })}
            </ToggleGroup.Root>
            {error && <p className="text-red-500 mt-1 text-sm">{error}</p>}
        </div>
    );
};

export default RadioButtonGroup;
