"use client";
// packages
import React, { useState, useEffect, useRef, RefObject, forwardRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import kebabCase from "lodash/kebabCase";

// media
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";

// components
import { Button, Command, CommandList, CommandGroup, CommandInput, Popover, PopoverContent, PopoverTrigger, CommandEmpty } from "@/shared/components/ui";

// utils
import { cn } from "@/shared/utils";

// types
import { Option } from "@/shared/types/Quote.interface";

interface ComboboxProps {
    options: Option[];
    isLoading?: boolean;
    error?: string | null;
    placeholder?: string;
    value?: string;
    onChange?: (value: string) => void;
    keyByLabel?: boolean;
    height?: string;
    disabled?: boolean;
    triggerProps?: React.HTMLAttributes<HTMLButtonElement>;
    withContainer?: boolean;
}

interface VirtualizedCommandProps {
    height: string;
    options: Option[];
    placeholder: string;
    selectedOption: string;
    onSelectOption: (option: string) => void;
    keyByLabel?: boolean;
}

// Virtualize dropdown options to handle large lists
const VirtualizedCommand = ({ height, options, placeholder, selectedOption, onSelectOption, keyByLabel }: VirtualizedCommandProps) => {
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
    const parentRef: RefObject<HTMLDivElement> = useRef(null);
    const virtualizer = useVirtualizer({
        count: filteredOptions.length,
        getScrollElement: () => parentRef.current!,
        estimateSize: () => 40, // dropdown option height
        overscan: 10
    });
    const virtualOptions = virtualizer.getVirtualItems();

    // Scroll to selected option if it exists
    useEffect(() => {
        const selectedIndex = options.findIndex(option => option.value === selectedOption);
        if (selectedIndex === -1) return;
        virtualizer.scrollToIndex(selectedIndex, { align: "center" });
    }, [virtualizer, options, selectedOption]);

    const handleSearch = (search: string) => {
        setFilteredOptions(options.filter(option => option.label.toLowerCase().includes(search.toLowerCase() ?? [])));
    };

    // Highlight current option, and scroll, on arrow up/down key press
    const handleKeyDown = (event: React.KeyboardEvent) => {
        const container = parentRef.current;
        if (!container) return;

        const currentElement = container.querySelector("[aria-selected=true]");
        const currentIndex = currentElement ? filteredOptions.findIndex(option => option.value === currentElement?.getAttribute("data-value")) : -1;

        const updateSelection = (nextIndex: number) => {
            if (currentElement) {
                currentElement.removeAttribute("aria-selected");
            }
            const nextElement = container.querySelector(`[data-value='${filteredOptions[nextIndex]?.value}']`);
            nextElement?.setAttribute("aria-selected", "true");
            virtualizer.scrollToIndex(nextIndex, { align: "auto" });
        };

        switch (event.key) {
            case "ArrowDown":
                updateSelection(currentIndex + 1);
                event.preventDefault();
                break;
            case "ArrowUp":
                updateSelection(currentIndex - 1);
                event.preventDefault();
                break;
            case "Enter":
                const newValue = currentElement?.getAttribute("data-value");
                onSelectOption(newValue as string);
                break;
            default:
                break;
        }
    };

    return (
        <Command shouldFilter={false} onKeyDown={handleKeyDown}>
            <CommandInput onValueChange={handleSearch} placeholder={placeholder} />
            <CommandList
                ref={parentRef}
                style={{
                    height: height
                }}
                className="w-full overflow-auto"
                onWheel={e => e.stopPropagation()}
                onTouchMove={e => e.stopPropagation()}
            >
                <CommandEmpty>{filteredOptions.length === 0 && <div className="py-5 text-center text-content-secondary">No match found.</div>}</CommandEmpty>
                <CommandGroup>
                    <div style={{ height: `${virtualizer.getTotalSize()}px` }} className="relative w-full">
                        {virtualOptions.map(virtualOption => {
                            const option = filteredOptions[virtualOption.index];
                            const selected = selectedOption === option?.value;
                            return (
                                option && (
                                    <div
                                        style={{
                                            height: `${virtualOption.size}px`,
                                            transform: `translateY(${virtualOption.start}px)`
                                        }}
                                        className="absolute left-0 top-0 flex w-full cursor-default select-none items-center rounded-sm p-2 text-sm text-content-primary hover:bg-background-secondary aria-selected:bg-background-secondary"
                                        key={keyByLabel ? option.label : option.value}
                                        onClick={() => onSelectOption(option.value)}
                                        aria-selected={selected || false}
                                        role="option"
                                        data-value={option.value}
                                        onMouseDown={e => e.preventDefault()} // Prevent click from unfocusing element
                                    >
                                        <Check size={16} className={cn("mr-2 size-4", selected ? "opacity-100" : "opacity-0")} />
                                        <span className="truncate" data-testid={kebabCase(option.label)}>
                                            {option.label}
                                        </span>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </CommandGroup>
            </CommandList>
        </Command>
    );
};

export const Combobox = forwardRef<HTMLDivElement, ComboboxProps>(
    ({ options, isLoading, error, placeholder = "", value, onChange, keyByLabel, height = "250px", disabled, triggerProps, withContainer = false }, ref) => {
        const containerRef = useRef<HTMLDivElement>(null);
        const [open, setOpen] = useState(false);
        const [internalValue, setInternalValue] = useState(value || "");

        useEffect(() => {
            if (value !== undefined) {
                setInternalValue(value);
            }
        }, [value]);

        const handleSelect = (selectedValue: string) => {
            const newValue = selectedValue === (value || internalValue) ? "" : selectedValue;
            if (onChange) {
                onChange(newValue);
            }
            setInternalValue(newValue);
            setOpen(false);
        };
        const comboTriggerClassConditions = {
            "text-content-primary": !!value,
            "shadow-faux-border-danger": !!error
        };
        return (
            <>
                {/* We add a hidden div to make sure the Select is focused on validation error (iOS-specific) */}
                <div tabIndex={-1} ref={ref} className="pointer-events-none absolute left-0 top-0 size-[1px] opacity-0"></div>
                <div ref={containerRef}>
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={open}
                                className={cn("relative w-full min-w-[200px] justify-between border-none font-normal shadow-faux-border", comboTriggerClassConditions)}
                                disabled={disabled}
                                {...triggerProps}
                            >
                                <span className="absolute max-w-[80%] truncate sm:max-w-[85%]">
                                    {value || internalValue ? options.find(option => option.value === (value || internalValue))?.label : (placeholder ?? `Choose an option`)}
                                </span>
                                {isLoading ? (
                                    <div className="absolute right-3 z-10 flex h-11 items-center text-content-brand">
                                        <Loader2 className="animate-spin" />
                                    </div>
                                ) : (
                                    <ChevronsUpDown className="absolute right-4 size-4 opacity-50" />
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            className={cn("z-[100] border border-stroke-primary bg-background-primary p-0", "width-equal-to-popover-trigger")}
                            style={{ pointerEvents: "auto" }}
                            onWheel={e => e.stopPropagation()}
                            onTouchMove={e => e.stopPropagation()}
                            container={withContainer ? containerRef.current : undefined}
                        >
                            <VirtualizedCommand
                                height={height}
                                options={options}
                                keyByLabel={keyByLabel}
                                placeholder={placeholder}
                                selectedOption={internalValue}
                                onSelectOption={currentValue => {
                                    handleSelect(currentValue);
                                }}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </>
        );
    }
);

Combobox.displayName = "Combobox";
